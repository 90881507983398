import { authGuard } from '@/utils';

const KudosDashboard = () => import(/* webpackChunkName: "kudos" */ './views/KudosDashboard.vue');
const KudosWallCreate = () => import(/* webpackChunkName: "kudos" */ './views/KudosWallCreate.vue');
const KudosWallEdit = () => import(/* webpackChunkName: "kudos" */ './views/KudosWallEdit.vue');
const KudosWallView = () => import(/* webpackChunkName: "kudos" */ './views/KudosWallView.vue');
const KudoCardView = () => import(/* webpackChunkName: "kudos" */ './views/KudoCardView.vue');

export default [
  {
    path: '/kudos',
    alias: '/k',
    name: 'kudos-list',
    component: KudosDashboard,
    beforeEnter: authGuard,
  },
  {
    path: '/kudos/create',
    alias: '/k/c',
    name: 'kudos-create',
    component: KudosWallCreate,
    beforeEnter: authGuard,
  },
  {
    path: '/kudos/:id/edit',
    alias: '/k/:id/e',
    name: 'kudos-edit',
    props: true,
    component: KudosWallEdit,
    beforeEnter: authGuard,
  },
  {
    path: '/kudos/:id',
    alias: '/k/:id',
    name: 'kudos-view',
    props: true,
    component: KudosWallView,
    beforeEnter: authGuard,
  },
  {
    path: '/kudos/:id/kudo/:kudoId',
    alias: '/k/:id/k/:kudoId',
    name: 'kudo-view',
    props: true,
    component: KudoCardView,
    beforeEnter: authGuard,
  },
];
