import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { store as UserStore } from '@/modules/user';
import { store as TimerStore } from '@/modules/timer';
import { store as PokerStore } from '@/modules/poker';
import { store as RetrospectiveStore } from '@/modules/retro';
import { store as SurveysStore } from '@/modules/surveys';
import { store as QuizStore } from '@/modules/quiz';
import { store as KudosStore } from '@/modules/kudos';
import { store as GoalsStore } from '@/modules/goals';
import { store as SkillsStore } from '@/modules/skills';
//import { store as BacklogsStore } from '@/modules/backlogs';
//import { store as BoardsStore } from '@/modules/boards';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    user: UserStore,
    timer: TimerStore,
    poker: PokerStore,
    retro: RetrospectiveStore,
    surveys: SurveysStore,
    quiz: QuizStore,
    kudos: KudosStore,
    goals: GoalsStore,
    skills: SkillsStore,
    //backlogs: BacklogsStore,
    //boards: BoardsStore,
  },
  plugins: [createPersistedState()], // TODO: consider using encrypted storage - see https://github.com/robinvdvleuten/vuex-persistedstate
});

export default store;
