
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Items, Settings, User } from '@/models';

@Component
export default class Navigation extends Vue {
  @Prop({ type: Array as () => Items, default: () => [] })
  readonly navItems!: Items;

  dark = false;
  drawer = false;
  drawerMinimized = false;
  year = new Date().getFullYear();
  version = process.env.VUE_APP_VERSION || '1.0.0';

  get user(): User {
    return this.$store.getters['user/user'];
  }
  get settings(): Settings {
    return this.$store.getters['user/settings'];
  }
  get items(): Items {
    return this.$store.getters['user/navigationItems'];
  }
  get themeIcon(): string {
    return this.dark ? 'mdi-brightness-7' : 'mdi-brightness-4';
  }
  get themeTitle(): string {
    return this.dark ? 'Light theme' : 'Dark theme';
  }
  get drawerMinimizedIcon(): string {
    return this.drawerMinimized ? 'mdi-forwardburger' : 'mdi-backburger';
  }
  get drawerMinimizedTitle(): string {
    return this.drawerMinimized ? 'Maximize navigation' : 'Minimize navigation';
  }

  @Watch('drawer')
  onDrawerChange(value) {
    this.settings.navigation.open = value;
    this.$store.dispatch('user/setSettings', this.settings);
  }

  mounted() {
    this.$vuetify.theme.dark = this.dark = this.settings.theme.dark;
    this.drawer = this.settings.navigation.open;
    this.drawerMinimized = this.settings.navigation.mini;

    this.$bus.$on('navigation-toggle', this.onNavigationToggle);
    this.$bus.$on('navigation-change', this.onNavigationChange);
  }

  toggleDarkMode() {
    this.$vuetify.theme.dark = this.dark = this.settings.theme.dark = !this.settings.theme.dark;
    this.$store.dispatch('user/setSettings', this.settings);
  }
  toggleNavigationMini() {
    this.drawerMinimized = this.settings.navigation.mini = !this.settings.navigation.mini;
    this.$store.dispatch('user/setSettings', this.settings);
  }
  onNavigationToggle() {
    //this.drawer = this.settings.navigation.open = !this.settings.navigation.open;
    this.drawer = !this.settings.navigation.open;
    this.$store.dispatch('user/setSettings', this.settings);
  }
  onNavigationChange(items: Items) {
    this.$store.dispatch('user/setNavigation', items);
  }
}
