import { authGuard } from '@/utils';

const SkillMatrixDashboard = () => import(/* webpackChunkName: "skills" */ './views/SkillMatrixDashboard.vue');
const SkillMatrixCreate = () => import(/* webpackChunkName: "skills" */ './views/SkillMatrixCreate.vue');
const SkillMatrixEdit = () => import(/* webpackChunkName: "skills" */ './views/SkillMatrixEdit.vue');
const SkillMatrixView = () => import(/* webpackChunkName: "skills" */ './views/SkillMatrixView.vue');

export default [
  {
    path: '/skills',
    alias: '/sk',
    name: 'skills-list',
    component: SkillMatrixDashboard,
    beforeEnter: authGuard,
  },
  {
    path: '/skills/create',
    alias: '/sk/c',
    name: 'skills-create',
    component: SkillMatrixCreate,
    beforeEnter: authGuard,
  },
  {
    path: '/skills/:id/edit',
    alias: '/sk/:id/e',
    name: 'skills-edit',
    props: true,
    component: SkillMatrixEdit,
    beforeEnter: authGuard,
  },
  {
    path: '/skills/:id',
    alias: '/sk/:id',
    name: 'skills-view',
    props: true,
    component: SkillMatrixView,
    beforeEnter: authGuard,
  },
];
