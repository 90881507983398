import colors from 'vuetify/lib/util/colors';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import rt from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
dayjs.extend(utc);
dayjs.extend(rt);
dayjs.extend(duration);

import uid from 'uid';
import { user, auth } from '@/firebase';
export { user, auth };

export function calculatePercent(value1: number, value2: number, decimals = 0): string | null {
  if (value1 === null || value2 === null) return null;
  const percent = (value1 / value2) * 100;
  return percent.toFixed(decimals);
}

export function formatDate(date?: number | null, format = 'DD-MMM-YYYY'): string | null {
  return date ? dayjs(date).format(format) : null;
}

export function dateToNow(date: number | null): string | null {
  //return date ? dayjs().to(dayjs(date)) : null;
  return date ? dayjs(date).fromNow() : null;
}

export function dateFromNow(date: number | null): string | null {
  return date ? dayjs(date).toNow() : null;
}

export function formatInterval(startDate: number | null, endDate: number | null, format = 'HH:mm:ss'): string | null {
  return startDate && endDate ? dayjs.utc(dayjs(endDate).diff(startDate)).format(format) : null;
}

export type DurationUnits = 'milliseconds' | 'seconds' | 'minutes' | 'hours' | 'days' | 'months' | 'years' | 'weeks';

export function formatDuration(
  value: number | null,
  units: DurationUnits = 'seconds',
  format = 'HH:mm:ss'
): string | null {
  return value !== null ? dayjs.utc(dayjs.duration(value, units).asMilliseconds()).format(format) : null;
}

export function formatPercent(value: number | null | undefined, decimals = 0, na = 'n/a'): string {
  if (value === null || value === undefined) return na;
  const percent = value * 100;
  return percent.toFixed(decimals);
}

export function formatValue(value: number | null, decimals = 0, parse = false) {
  if (value === null) return null;
  return parse ? parseFloat(value.toFixed(decimals)) : value.toFixed(decimals);
}

export function getStatusColor(status: string | null): string {
  let color = 'default';
  switch (status) {
    case 'Draft':
      color = 'warning';
      break;
    case 'Started':
      color = 'info';
      break;
    case 'Active':
    case 'Completed':
      color = 'success';
      break;
    case 'Closed':
      color = 'error';
      break;
    default:
      color = 'default';
  }
  return color;
}

export function getUserAvatar(uid: string): string {
  return `https://robohash.org/${uid}.png`;
}

export function getPexelPhoto(id: number, width = 1260, height = 750): string {
  return `https://images.pexels.com/photos/${id}/pexels-photo-${id}.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=${height}&w=${width}`;
}

// eslint-disable-next-line
export async function authGuard(to, from, next) {
  const currentUser = await user();
  if (!currentUser) {
    // Not authenticated -> redirect to Login
    next({ name: 'login', query: { redirectFrom: to.fullPath } });
  } else if (!currentUser.displayName) {
    // No displayName (username) set -> redirect to Profile
    next({ name: 'profile', query: { redirectFrom: to.fullPath } });
  } else {
    // All good - continue with next route
    next();
  }
  //currentUser ? next() : next({ name: 'login', query: { redirectFrom: to.fullPath } });
}

export function getId(size = 8) {
  return uid(size);
}

export function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function deepCopy(obj: any): any {
  return JSON.parse(JSON.stringify(obj));
}

export function downloadJson(exportObj: any, exportName: string, space = 2) {
  const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj, null, space));
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', exportName + '.json');
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export function hexColor(name: string) {
  const [nameFamily, nameModifier] = name.split(' ');
  const shades = ['black', 'white', 'transparent'];
  const util = { family: null as string | null, modifier: null as string | null };
  if (shades.find((shade) => shade === nameFamily)) {
    util.family = 'shades';
    util.modifier = nameFamily;
  } else {
    const [firstWord, secondWord] = nameFamily.split('-');
    util.family = `${firstWord}${secondWord ? secondWord.charAt(0).toUpperCase() + secondWord.slice(1) : ''}`;
    util.modifier = nameModifier ? nameModifier.replace('-', '') : 'base';
  }
  return colors[util.family][util.modifier];
}

export default {
  user,
  auth,
  calculatePercent,
  formatDate,
  formatInterval,
  formatDuration,
  formatPercent,
  formatValue,
  getStatusColor,
  getUserAvatar,
  authGuard,
  getId,
  sleep,
  downloadJson,
  hexColor,
};
