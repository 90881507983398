export default {
  namespaced: true,

  state: {
    viewMode: 'grid',
  },

  getters: {
    viewMode: (state) => state.viewMode,
    viewModeIcon: (state) => (state.viewMode === 'grid' ? 'mdi-view-list' : 'mdi-view-grid'),
  },

  mutations: {
    setViewMode: (state, viewMode) => (state.viewMode = viewMode),
    toggleViewMode: (state) => (state.viewMode === 'grid' ? (state.viewMode = 'list') : (state.viewMode = 'grid')),
  },

  actions: {
    setViewMode: (context, viewMode) => context.commit('setViewMode', viewMode),
    toggleViewMode: (context) => context.commit('toggleViewMode'),
  },
};
