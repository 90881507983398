import { authGuard } from '@/utils';

const SurveyDashboardView = () => import(/* webpackChunkName: "surveys" */ './views/SurveyDashboardView.vue');
const SurveyCreateView = () => import(/* webpackChunkName: "surveys" */ './views/SurveyCreateView.vue');
const SurveyTakeView = () => import(/* webpackChunkName: "surveys" */ './views/SurveyTakeView.vue');
const SurveyPreviewView = () => import(/* webpackChunkName: "surveys" */ './views/SurveyPreviewView.vue');
const SurveyEditView = () => import(/* webpackChunkName: "surveys" */ './views/SurveyEditView.vue');
const SurveyResultsView = () => import(/* webpackChunkName: "surveys" */ './views/SurveyResultsView.vue');
const SurveyCloneView = () => import(/* webpackChunkName: "surveys" */ './views/SurveyCloneView.vue');

export default [
  {
    path: '/surveys',
    alias: '/s',
    name: 'surveys',
    component: SurveyDashboardView,
    beforeEnter: authGuard,
  },
  {
    path: '/surveys/create',
    alias: '/s/c',
    name: 'survey-create',
    component: SurveyCreateView,
    beforeEnter: authGuard,
  },
  {
    path: '/surveys/:id',
    alias: '/s/:id',
    name: 'survey-view',
    props: true,
    // meta: { layout: 'simple-layout', title: 'Survey' },
    component: SurveyTakeView,
  },
  {
    path: '/surveys/:id/preview',
    alias: '/s/:id/p',
    name: 'survey-preview',
    props: true,
    component: SurveyPreviewView,
  },
  {
    path: '/surveys/:id/edit',
    alias: '/s/:id/e',
    name: 'survey-edit',
    props: true,
    component: SurveyEditView,
    beforeEnter: authGuard,
  },
  {
    path: '/surveys/:id/results',
    alias: '/s/:id/r',
    name: 'survey-results',
    props: true,
    component: SurveyResultsView,
  },
  {
    path: '/surveys/:id/clone',
    alias: '/s/:id/c',
    name: 'survey-clone',
    props: true,
    component: SurveyCloneView,
    beforeEnter: authGuard,
  },
];
