import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import minifyTheme from 'minify-css-string';

Vue.use(Vuetify);

export default new Vuetify({
  rtl: false,
  theme: {
    options: {
      //customProperties: true
      minifyTheme,
    },
    themes: {
      dark: {
        primary: '#03A9F4',
        secondary: '#FFC107',
        accent: '#607D8B',
        success: '#4CAf50',
        info: '#00BCD4',
        warning: '#FF9800',
        error: '#F44336',
        background: colors.grey.darken2,
      },
      light: {
        primary: '#03A9F4',
        secondary: '#E6B00E',
        accent: '#607D8B',
        success: '#4CAf50',
        info: '#00BCD4',
        warning: '#FF9800',
        error: '#F44336',
        background: colors.indigo.lighten5,
      },
    },
  },
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
});
