import { authGuard } from '@/utils';

const Dashboard = () => import(/* webpackChunkName: "goals" */ './views/Dashboard.vue');

export default [
  {
    path: '/goals',
    alias: '/g',
    name: 'goals-list',
    component: Dashboard,
    beforeEnter: authGuard,
  },
];
