//import { defaultThemes, defaultSurvey } from './defaults';

export default {
  /*
  namespaced: true,

  state: {
    survey: defaultSurvey,
    surveys: [],
    theme: defaultThemes[0],
  },

  getters: {
    survey: (state) => state.survey,
    newSurvey: (state) => (state.survey = { ...defaultSurvey }),
    surveys: (state) => state.surveys,
    theme: (state) => state.theme,
  },

  mutations: {
    setSurvey: (state, survey) => (state.survey = survey),
    setSurveys: (state, surveys) => (state.surveys = surveys),
    setTheme: (state, theme) => (state.theme = theme),
  },

  actions: {
    setSurvey: (context, survey) => context.commit('setSurvey', survey),
    setSurveys: (context, surveys) => context.commit('setSurveys', surveys),
    setTheme: (context, theme) => context.commit('setTheme', theme),
  },
  */
};
