// import '@babel/polyfill';
// import 'whatwg-fetch';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/vuefire';
import './plugins/bus';
import './plugins/youtube';
import './plugins/confetti';
import './plugins/charts';
import './plugins/firebase';

// Icons & fonts
//import 'roboto-fontface';
//import 'typeface-barlow-condensed';
import '@mdi/font/css/materialdesignicons.min.css';
//import 'material-design-icons-iconfont/dist/material-design-icons.css';
//import '@/scss/main.scss';

Vue.config.devtools = false;
Vue.config.productionTip = false;

// Global properties
Vue.prototype.$processing = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
