import { emit } from 'process';

export enum VIEW_MODE {
  GRID = 'grid',
  LIST = 'list',
}

export enum FILTER_MODE {
  ALL = 'all',
  OWNER = 'owner',
  MEMBER = 'member',
}

export default {
  namespaced: true,

  state: {
    viewMode: 'grid' as VIEW_MODE,
    filterMode: 'all' as FILTER_MODE,
  },

  getters: {
    viewMode: (state) => state.viewMode,
    viewModeIcon: (state) => (state.viewMode === VIEW_MODE.GRID ? 'mdi-view-list' : 'mdi-view-grid'),
    filterMode: (state) => state.filterMode,
  },

  mutations: {
    setViewMode: (state, viewMode) => (state.viewMode = viewMode),
    toggleViewMode: (state) =>
      state.viewMode === VIEW_MODE.GRID ? (state.viewMode = VIEW_MODE.LIST) : (state.viewMode = VIEW_MODE.GRID),
    setFilterMode: (state, filterMode) => (state.filterMode = filterMode),
  },

  actions: {
    setViewMode: (context, viewMode) => context.commit('setViewMode', viewMode),
    toggleViewMode: (context) => context.commit('toggleViewMode'),
    setFilterMode: (context, filterMode) => context.commit('setFilterMode', filterMode),
  },
};
