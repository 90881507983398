import { Settings, Team, FirebaseUser, User } from '@/models';
import { auth } from '@/firebase';
import { userService } from './services';

export const VIEW_MODE_GRID = 'grid';
export const VIEW_MODE_LIST = 'list';

const store = {
  namespaced: true,

  state: {
    user: null as User | null,
    team: null as Team | null,
    settings: {
      theme: { dark: false },
      navigation: { open: false, mini: false, items: [] },
    } as Settings | null,
    connected: navigator.onLine,
    viewMode: VIEW_MODE_GRID as typeof VIEW_MODE_GRID | typeof VIEW_MODE_LIST,
  },

  getters: {
    firebaseUser: () => auth.currentUser as FirebaseUser,
    user: (state) => state.user as User,
    userId: (state) => (state.user ? state.user.id : null) as string | null,
    authenticated: (state) => (state.user !== null) as boolean,
    username: (state) => (state.user ? state.user.username : null) as string | null,
    avatar: (state) => (state.user ? state.user.avatar : null) as string | null,
    settings: (state) => state.settings as Settings,
    navigationItems: (state) => state.settings.navigation.items,
    team: (state) => state.team as Team | null,
    connected: (state) => state.connected as boolean,
    viewMode: (state) => state.viewMode,
    viewModeIcon: (state) => (state.viewMode === VIEW_MODE_GRID ? 'mdi-view-list' : 'mdi-view-grid'),
    viewModeGrid: (state) => state.viewMode === VIEW_MODE_GRID,
    viewModeList: (state) => state.viewMode === VIEW_MODE_LIST,
  },

  mutations: {
    setUser: (state, user) => (state.user = user),
    setTeam: (state, team) => (state.team = team),
    setSettings: (state, settings) => (state.settings = settings),
    setNavigation: (state, items) => (state.settings.navigation.items = items),
    setConnected: (state, connected) => (state.connected = connected),
    setViewMode: (state, viewMode) => (state.viewMode = viewMode),
  },

  actions: {
    setTeam: (context, team) => context.commit('setTeam', team),
    setSettings: (context, settings) => context.commit('setSettings', settings),
    setNavigation: (context, items) => context.commit('setNavigation', items),
    setConnected: (context, connected) => context.commit('setConnected', connected),
    refreshUser: (context) => context.commit('setUser', userService.convertUser(auth.currentUser)),
    authAction: (context) => {
      auth.onAuthStateChanged((firebaseUser: FirebaseUser | null) => {
        const user = userService.convertUser(firebaseUser);
        context.commit('setUser', user);
        //console.log('[auth] User state changed', firebaseUser, user, store.state.user, store);
      });
    },
    setViewMode: (context, viewMode) => context.commit('setViewMode', viewMode),
    toggleViewMode: (context) =>
      context.commit('setViewMode', context.state.viewMode === VIEW_MODE_GRID ? VIEW_MODE_LIST : VIEW_MODE_GRID),
  },
};

export default store;
